import React, { useEffect, useState, useRef } from 'react';
import "../components/MainPanel.css";
import { Link } from 'react-router-dom';
import {getViewership, getContentByContentId, newStream} from './ContentManip';
import { getViewerByHash } from '../userLogin/UserManip';
import BCLoadingPage from '../components/BCLoadingPage';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import { viewerNonceQuery, viewershipVerification, addProof } from '../blockchainManip/BCManip';
import { viewerSign } from './Encyption';
import {getContentCode} from '../blockchainManip/GetCode';
import {hoverDeleteHandler, unhoverDeleteHandler} from '../components/ButtonManip';

const Content = ({translator, viewerHash}) => {
    const [viewerBuyAccess, setViewerBuyAccess] = useState(null);
    const [contentStateId, setContentStateId] = useState("");
    const [content, setContent] = useState(null);
    const videoRef = useRef(null);
    const [videoSrc, setVideoSrc] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [viewerNotExistError, setViewerNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const viewerData = await getViewerByHash(viewerHash).then(res => res.data);
            if(viewerData===undefined || viewerData.length===0){
                setViewerNotExistError(true);
            } 

            const uri = window.location.href;
            const uriArray = uri.split("/");
            const contentStateIdValue = uriArray[uriArray.length - 1];
            setContentStateId(contentStateIdValue);

            const viewership = await getViewership(contentStateIdValue, viewerHash).then(res => res.data);
            const contentData = await getContentByContentId(contentStateIdValue).then(res => res.data);
            setContent(contentData.length===0 ? contentData : contentData[0]);

            const contentViewership = JSON.parse(sessionStorage.getItem(contentStateIdValue));
                        
            if(viewership.length!==0 && contentData.length!==0 && contentViewership!==null){
                const bcContentId = getContentCode(contentViewership.content, contentViewership.cp);
                const contentFound = contentData[0];
                const channelName = contentFound.channel;
                if(contentViewership.verification===null){
                    try{
                        viewerNonceQuery(channelName, bcContentId).then(async (res) => {
                            const nonce = res.data.message;
                            var tokenValue = JSON.parse(sessionStorage.getItem(contentStateIdValue)).token;
                            viewerSign(tokenValue, nonce).then((signedNonce) => {
                                const viewer = {
                                    suppliedContentId: getContentCode(contentFound.content_unique_id, contentFound.content_provider_name),
                                    viewerId: viewerHash,
                                    nonce: nonce,
                                    nonceEncrypted: signedNonce
                                }
                                viewershipVerification(channelName, viewer).then(() => {
                                    let proof = {
                                        suppliedContentId: getContentCode(contentFound.content_unique_id, contentFound.content_provider_name),
                                        viewerId: viewerHash,
                                        nonce: nonce,
                                        encryptedNonce: signedNonce,
                                    }
                                    addProof(channelName, proof).then(() => {
                                        let stream = {
                                            contentUrl: contentFound.url,
                                            contentId: bcContentId
                                        }
                                        newStream(stream).then(() => {
                                            setViewerBuyAccess(true);
                                            const contentViewershipUpdated = contentViewership;
                                            contentViewershipUpdated.verification = true;
                                            sessionStorage.setItem(contentStateIdValue, JSON.stringify(contentViewershipUpdated));
                                            const videoSrcString = process.env.REACT_APP_SP_VID_SRC + "/" + bcContentId + "/output.m3u8"; 
                                            setVideoSrc(videoSrcString);
                                        }).catch(error => {
                                            if(error.response.status===409){
                                                setViewerBuyAccess(true);
                                                const contentViewershipUpdated = contentViewership;
                                                contentViewershipUpdated.verification = true;
                                                sessionStorage.setItem(contentStateIdValue, JSON.stringify(contentViewershipUpdated));
                                                const videoSrcString = process.env.REACT_APP_SP_VID_SRC + "/" + bcContentId + "/output.m3u8"; 
                                                setVideoSrc(videoSrcString);
                                            } else {
                                                console.log(error)
                                            }
                                        })
                                    }).catch(error => {
                                        setBcErrorOccured(true);
                                        setBcErr({name: error.name, msg: error.message})
                                    })
                                }).catch(error => {
                                    setBcErrorOccured(true);
                                    setBcErr({name: error.name, msg: error.message})
                                })
                            }).catch(error => {
                                console.log(error);
                            })
                        }).catch(error => {
                            setBcErrorOccured(true);
                            setBcErr({name: error.name, msg: error.message})
                        })
                    } catch(error){
                        console.error(error);
                    }
                } else {
                    if(contentViewership.verification===true){
                        setViewerBuyAccess(true);
                        const videoSrcString = process.env.REACT_APP_SP_VID_SRC + "/" + bcContentId + "/output.m3u8"; 
                        setVideoSrc(videoSrcString);
                    } else {
                        setViewerBuyAccess(false);
                    }
                }
            } else {
                setViewerBuyAccess(false);
            }
        };

        init();
    }, [viewerHash])

    useEffect(() => {
        const loadVideo = async () => {
            if (viewerBuyAccess === true && videoSrc!=="") {
                const video = videoRef.current;
                if (window.Hls.isSupported()) {
                    const hls = new window.Hls({
			            xhrSetup: (xhr, url) => {
			            	xhr.withCredentials = true
			            }
		            });
		            hls.loadSource(videoSrc)
                    hls.attachMedia(video);
                    hls.on(window.Hls.Events.MANIFEST_PARSED, function () {
                        video.play();
                      });
                
                      return () => {
                        hls.destroy();
                      };
                } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
		            console.log('not supported')
                    video.src = videoSrc;
                    video.addEventListener('loadedmetadata', () => {
                        video.play();
                    });
                }
            }
        };
    
        loadVideo();
    }, [viewerBuyAccess, videoSrc]);

    const viewerHaveAccess = () => {
        return(
            <video ref={videoRef} controls width={"900px"} />
        )
    }

    const viewerDoNotHaveAccess = () => {
        window.location.assign("/" + viewerHash + "/" + contentStateId + "/purchase");
    }

    if(viewerBuyAccess!==null){
        if(content!==null){
            if(content.length===0 || viewerNotExistError){
                return <Error1 translator={translator} />
            } else if(bcErrorOccured){
                return (
                    <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
                )
            } else {
                if(viewerBuyAccess === false){
                    viewerDoNotHaveAccess();
                    return null;
                } else {
                    return(
                        <div className='wrapper'>
                            <div className='wrapper'>
                                <Navbar translator={translator} />
                                <div className="main-panel-with-footer">
                                    <div className="video-page-container">
                                        {viewerHaveAccess()}
                                        <Link to={"/" + viewerHash + "/contents"} >
                                            <button className='n7-button delete-button' style={{cursor: "pointer"}} onMouseEnter={hoverDeleteHandler} onMouseLeave={unhoverDeleteHandler}>{translator("return")}</button>
                                        </Link>
                                    </div>
                                    <Foot translator={translator} />
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
    } else {
        return <BCLoadingPage translator={translator} />
    }
}

export default Content;
